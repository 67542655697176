import React from "react";

import Layout from "../components/layout";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SectionTitle from "../components/section_title";
import PreviewPlayer from "../components/PreviewPlayer";

import tw from "tailwind.macro";

const styles = {
  wrap: tw`bg-grey-lightest`,
  contentBox: tw`py-16 max-w-4xl mx-auto px-5`,
  playerBox: tw`pt-12`
};

const CourseOverview = () => (
  <Layout>
    <div css={styles.wrap}>
      <Header />
      <div css={styles.contentBox}>
        <SectionTitle
          title="Course preview"
          description="Hosted by Head of TED Chris Anderson, the TED Masterclass course consists of 3 sections and 11 lessons. Each lesson takes about an hour to complete. Participants can progress through the course at your own pace."
        />
        <div css={styles.playerBox}>
          <PreviewPlayer showPlaylist />
        </div>
      </div>
    </div>
    <Footer />
  </Layout>
);

export default CourseOverview;
