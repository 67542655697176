import React from "react";
import tw from "tailwind.macro";
import { css } from "@emotion/core";

import Btn from "../../components/Btn";
import Image from "../../components/Image";

import { Inter5, Inter4, Inter2r } from "../../styles/blog";
import { xl } from "../../styles/breakpoints";
import { rem } from "../../styles/utilities.styles.js";

const styles = {
  wrap: tw`mb-10`,
  titleLarge: tw`tracking-tight font-bold text-center text-5xl xxl:text-6xl mb-4`,
  title: tw`text-3xl md:text-4xl xl:text-4xl xxl:text-4xl font-bold text-center mb-4 tracking-tight`,
  text: tw`leading-normal text-center text-lg lg:text-xl mx-auto mb-4 max-w-lg`,
};

// intro: tw`mt-12 xl:mt-24 mb-12 max-w-4xl mx-auto px-5`,

const styles2 = {
  wrap: css`
    max-width: ${rem(800)};
    padding: ${rem(48)} ${rem(20)} 0;
    margin: auto;

    @media (${xl}) {
      padding: ${rem(96)} ${rem(20)} 0;
    }
  `,
  title: css`
    margin-bottom: ${rem(24)};
    ${Inter4};
    text-align: center;

    @media (${xl}) {
      ${Inter5};
    }
  `,
  img: css`
    max-width: ${rem(616)};
    margin: 0 auto ${rem(48)};
  `,
  btn: css`
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.06),
      0px 4px 5px rgba(0, 0, 0, 0.07), 0px 2px 4px rgba(0, 0, 0, 0.05);
  `,
  text: css`
    margin-bottom: ${rem(48)};
    ${Inter2r};
    text-align: center;
  `,
};

const SectionTitle = ({ large, title, description, className }) => (
  <section css={styles.wrap} className={className}>
    <h1 css={large ? styles.titleLarge : styles.title}>{title}</h1>
    <p css={styles.text}>{description}</p>
  </section>
);

export const SectionTitleAlt = ({
  img,
  title,
  description,
  cta,
  className,
}) => (
  <section css={styles2.wrap} className={className}>
    <h1 css={styles2.title}>{title}</h1>
    <p css={styles2.text}>{description}</p>
    <Image css={styles2.img} image={img} />
    <Btn css={styles2.btn} {...cta} center />
  </section>
);

export default SectionTitle;
